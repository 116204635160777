<!--公共模块--输出设备表格组件-->
<template>
  <div id="outCtrlTable">
    <a-table :columns="columns" :dataSource="data" :pagination="false" bordered :scroll="{ x: 1300 }">
      <span slot="operate" slot-scope="record">      
        <a>{{ record.operate }}</a>
        <a-divider type="vertical" />
        <a>{{ record.isOperate }}</a>
      </span>
      <span slot="status" slot-scope="record">      
        <a>{{ record.status }}</a>
        <a-divider type="vertical" />
        <a>{{ record.isStatus }}</a>
      </span>
      <span slot="alert" slot-scope="record">      
        <a>{{ record.alert }}</a>
        <a-divider type="vertical" />
        <a>{{ record.isAlerte }}</a>
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "outCtrlTable",
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 70,
          fixed: "left",
          align: 'center'
        },
        {
          title: "Gr1",
          dataIndex: "gr1",
          width: 100,
          align: 'center'
        },
        {
          title: "Gr2",
          dataIndex: "gr2",
          width: 80,
          align: 'center'
        },
        {
          title: "Gr3",
          dataIndex: "gr3",
          width: 80,
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b4'),
          dataIndex: "dvName",
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b5'),
          dataIndex: "signalName",
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b6'),
          dataIndex: "signalType",
          width: 80,
          align: 'center'
        },
        {
          title: "TagPoint",
          dataIndex: "tagPoint",
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b7'),
          children: [          
            {
              title: this.$t('controlllinkage.b8'),
              width: 80,
              ellipsis: true,   
              align:'center',    
              scopedSlots: { customRender: 'operate' },
            },
            {
              title: this.$t('controlllinkage.b1'),
              width: 80,
              ellipsis: true,   
              align:'center',    
              scopedSlots: { customRender: 'status' },
            },
            {
              title: this.$t('controlllinkage.b2'),
              width: 80,
              ellipsis: true,   
              align:'center',    
              scopedSlots: { customRender: 'alert' },
            },
          ],
        }
      ],
    };
  },
};
</script>
<style scoped>
#outCtrlTable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-left: 1px solid #dedfdf;  
  border-right: 1px solid #dedfdf;
}
</style>