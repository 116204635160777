<!--设备模块--联动控制页-->
<template>
  <div id="controllLinkage" v-loading="loading" :element-loading-text="$t('controlllinkage.a10')">
    <!-- 页面标题 -->
    <my-PageTitle>{{ $t('controlllinkage.a11') }}</my-PageTitle>
    <!-- 内容区域 -->
    <div class="content">
      <!-- 侧边导航栏 -->
      <div class="aside">
        <my-navder :list="asideList" @select="getMsgFormSon"></my-navder>
      </div>
      <!-- 数据显示区域 -->
      <div class="main" v-if="id">
        <!-- 名称栏 -->
        <div class="node_name">
          <div>
            <span>{{ nodeParentName }}：</span>
            <span>{{ nodeName }}</span>
            <a-icon type="form" :style="{ fontSize: '15px', color: '#7682CE', 'margin-left': '15px'}" @click="openNameUpdata"/>
          </div>
          <div>
            <span>{{$t('controlllinkage.a2')}}</span>
            <span>{{ nodeId }}</span>
          </div>
        </div>
        <!-- 表格 -->
        <div class="content_table">
          <my-tabletitle>{{$t('controlllinkage.a3')}}</my-tabletitle>
          <my-inputTable :data="inputList"></my-inputTable>
          <my-tabletitle>{{$t('controlllinkage.a4')}}</my-tabletitle>
          <my-outputTable :data="outputList"></my-outputTable>
          <my-tabletitle>{{$t('controlllinkage.a5')}}</my-tabletitle>
          <my-conditionTable :data="interlockList"></my-conditionTable>
          <my-tabletitle>{{$t('controlllinkage.a6')}}</my-tabletitle>
          <my-conditionLinkageTable :data="linkageList"></my-conditionLinkageTable>
        </div>
        <!-- 底栏 -->
        <div class="footer">
          <a-button type="primary" @click="Setting">{{$t('controlllinkage.a8')}}</a-button>
        </div>
      </div>
      <!-- 空数据 -->
      <div class="empty" v-else>
        <a-empty />
      </div>
    </div>

    <!-- 名称修改对话框 -->
    <a-modal :title="$t('controlllinkage.a12')" :width="300" v-model="visible" centered v-drag :destroyOnClose="true">
      <template slot="footer">
        <a-button key="back" @click="NameUpdataCancel">{{ $t('controlllinkage.a13') }}</a-button>
        <a-button key="submit" type="primary" @click="nameUpdate" :disabled="nameUpdataDisabled">{{ $t('controlllinkage.a14') }}</a-button>
      </template>
      <div class="name_content" v-loading="nameUpdataLoading" :element-loading-text="$t('controlllinkage.a10')">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item ref="nodeNameUpdata" :label="$t('controlllinkage.a15')" prop="nodeNameUpdata">
            <a-input v-model="form.nodeNameUpdata" allowClear @blur="() => { $refs.name.onFieldBlur() }" :placeholder="$t('controlllinkage.a16')"/>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle'
import tabletitle from '../../components/Title/tabletitle'
import navdar from '../../components/Navdar/vertical'
import conditionLinkageTable from './conditionLinkageTable'
import conditionTable from './conditionTable'
import inputTable from './inCtrlTable'
import outputTable from './outCtrlTable'
import { lightTimetableListKT,getLinkageCtrlInfo,updateScheduleName,updateLinkageCtrlInfo } from '../../api/device'

export default {
  data() {
    return {
      loading: false,
      asideList: [],
      id: undefined,//包含类型和时间表ID
      schType: 9,
      nodeId: undefined,//树节点ID
      nodeName: undefined,//树节点名称
      nodeParentName: undefined,//父节点名称
      nodeParentId: undefined,
      form: { nodeNameUpdata: '' },
      inputList:[],
      outputList:[],
      interlockList:[],
      linkageList:[],
      visible: false,
      nameUpdataLoading: false,
      nameUpdataDisabled: false,
      rules: {
        nodeNameUpdata: [
          { required: true, message: this.$t('controlllinkage.a17'), trigger: 'blur' },
        ],
      },
    };
  },
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    this.navderList()
  },
  methods: {
    // 获取页面左侧列表方法
    navderList() {
      this.loading = true 
      let name = this.$route.name
      this.schType = undefined
      // if(name=="VRFAC_FAC_KIKIRENDOU" || name=="LIGHTING_FAC_KIKIRENDOU"||name=="ENVIRONMENT_FAC_KIKIRENDOU"||name=="BSDSAC_FAC_KIKIRENDOU"||name=="FACILITIES_FAC_KIKIRENDOU")
      if( name.indexOf('FAC_KIKIRENDOU') >= 0 )
          this.schType = 6
      if( !this.schType ){
        return
      }
      let par = { siteId: this.$route.query.id, schType: this.schType }
      // console.log("ControllLinkage KIKIRENDOU par:", par)
      lightTimetableListKT(par)
        .then((res) => {
          // console.log("ControllLinkage KIKIRENDOU res:", res)
          let { data } = res
          data.key = "sub1"
          this.asideList.length = 0
          this.asideList.push(data)
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data
      const nodeId = data.split(",")[1]
      this.nodeId = nodeId
      this.menuRecursion(this.asideList)
      const par = { siteId: this.$route.query.id, linkageCtrlId: nodeId }  
      this.getdata(par)
    },
    // 侧边栏数据查找
    menuRecursion(treeData, nodeParent){
      const key = this.id
      for(let i = 0; i < treeData.length; i++){
        if(treeData[i].key === key){
          this.nodeName = treeData[i].title
          this.nodeParentName = nodeParent.title
          this.nodeParentId = nodeParent.key
        }
        if(treeData[i].children){ this.menuRecursion(treeData[i].children, treeData[i]) }
      }
    },
    // 加载页面数据的方法    
    getdata(par) {
      this.loading = true    
      getLinkageCtrlInfo(par)
        .then((res) => {
          let { data } = res;
          // console.log("ControllLinkage getdata-data:",data);
          this.inputList = data.inputList.map((item,index) => { item.key = index + 1; return item;});
          this.outputList = data.outputList.map((item,index) => { item.key = index + 1; return item;});
          this.interlockList = data.interlockList.map((item,index) => { item.key = index + 1; return item;});
          this.linkageList = data.linkageList.map((item,index) => { item.key = index + 1; return item;});          
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }, 
    // 打开名称修改对话框
    openNameUpdata(){
      this.visible = true
      this.form.nodeNameUpdata = this.nodeName
    },
    // 关闭名称修改对话框
    NameUpdataCancel(){
      this.visible = false
    }, 
    // 更新名称
    nameUpdate() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const asideList = this.asideList
          const newName = this.form.nodeNameUpdata
          const nodeParentId = this.nodeParentId
          const par = { scheId: this.nodeId, scheType: this.schType, newName }
          if(!this.duplicateNameChecking(asideList, nodeParentId, newName)){
            this.nameUpdataLoading = true
            this.nameUpdataDisabled = true
            // console.log("nameUpdate par:",par)
            updateScheduleName(par)
              .then((res) => {
                // console.log(res)
                if(res.errorCode === '00'){
                  this.nodeName = newName
                  this.treeNodeNameUpdata(this.asideList)
                  this.$message.success(res.msg)
                  this.visible = false
                }else{
                  this.$message.error(res.msg)
                }
                this.nameUpdataLoading = false
                this.nameUpdataDisabled = false
              })
              .catch((err) => {
                console.log(err)
                this.nameUpdataLoading = false
                this.nameUpdataDisabled = false
              });
          }else{
            this.$message.error(this.$t('controlllinkage.a9'))
          };
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重名检查--特定树形结构数据，同一父级下的子节点不可重名
    duplicateNameChecking(treeData, parentKey, title) {
      let isExist = false
      const treeRecursion = (nodes, nodeParentKey) => {
        for(let i = 0; i < nodes.length; i++){
          if(parentKey === nodeParentKey && title === nodes[i].title){ 
            isExist = true
            return
          }
          if(nodes[i].children){ treeRecursion(nodes[i].children, nodes[i].key) }
        }
      }
      treeRecursion(treeData)
      return isExist
    },
    // 名称修改请求成功后侧边名称修改
    treeNodeNameUpdata(treeData){
      treeData.forEach((item) => {
        if(item.key === this.id){ item.title = this.form.nodeNameUpdata }
        if(item.children){ this.treeNodeNameUpdata(item.children) }
      })
    },
    // 联动控制设置
    Setting() {
      this.loading = true
      let par = { id: this.nodeId, interlockList: this.interlockList, linkageList:this.linkageList }
      // console.log("Setting par:",par);
      updateLinkageCtrlInfo(par)
        .then((res) => {
          this.loading = false
          if (res.errorCode == "00") {
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
              this.$message.error(res.msg);
            }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        });
    }
  },
  components: {
    'my-tabletitle': tabletitle,
    'my-navder': navdar,
    'my-conditionLinkageTable': conditionLinkageTable,
    'my-conditionTable': conditionTable,
    'my-inputTable':inputTable,
    'my-outputTable':outputTable,
    'my-PageTitle': PageTitle
  },
};
</script>
<style scoped>
#controllLinkage {
  width: 100%;
  height: 100%;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.content{
  display: flex;
  height: calc(100% - 50px);
  padding-top: 15px;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: calc(100% - 250px);
  padding: 0px 0px 0px 15px;
}
.node_name{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  font-size: 14px;
  padding-bottom: 10px;
}
.content_table{
  height: calc(100% - 85px);
  padding-right: 20px;
  overflow: auto;
}
.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 250px);
  height: 100%;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
.name_content{
  height: 100px;
}
</style>