<!--公共模块--联动条件表格组件-->
<template>
  <div id="conditionTable">
    <a-table :columns="columns" :dataSource="data" :pagination="false" bordered :scroll="{ x: 1300 }">
      <span slot="valid" slot-scope="record">
        <a-radio-group v-model="record.isInterlock">
          <a-radio :value="1">
            {{$t('controlllinkage.b9')}}
          </a-radio>
          <a-radio :value="0">
            {{$t('controlllinkage.b10')}}
          </a-radio>            
        </a-radio-group>
      </span>
      <span slot="condition" slot-scope="record">
        <a-select default-value="1" v-model="record.condition">
          <a-select-option :value="1">
            {{$t('controlllinkage.b18')}}
          </a-select-option>
          <a-select-option :value="0">
            {{$t('controlllinkage.b19')}}
          </a-select-option>        
        </a-select>
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "conditionTable",
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("controlllinkage.b13"),
          width: 150,
          fixed: "left",
          scopedSlots: { customRender: 'valid' },
          align: 'center'
        },
        {
          title: "Gr1",
          dataIndex: "gr1",
          width: 120,
          align: 'center'
        },
        {
          title: "Gr2",
          dataIndex: "gr2",
          width: 80,
          align: 'center'
        },
        {
          title: "Gr3",
          dataIndex: "gr3",
          width: 80,
          align: 'center'
        },
        {
          title: this.$t("controlllinkage.b4"),
          dataIndex: "dvName",
          align: 'center'
        },
        {
          title: this.$t("controlllinkage.b5"),
          dataIndex: "signalName",
          align: 'center'
        },
        {
          title: this.$t("controlllinkage.b17"),
          dataIndex: "signalType",
          width: 80,
          align: 'center'
        },
        {
          title: "TagPoint",
          dataIndex: "tagPoint",
          width: 100,
          align: 'center'
        },        
        {
          title: this.$t("controlllinkage.b1"),
          dataIndex: "status",
          width: 80,
          align: 'center'
        },
        {
          title: this.$t("controlllinkage.b2"),
          dataIndex: "alert",
          width: 80,
          align: 'center'
        },
        {
          title: this.$t("controlllinkage.b6"),
          // dataIndex: "condition",
          width: 80,
          align: 'center',
          scopedSlots: { customRender: 'condition' },
        },
      ],
    };
  }
};
</script>
<style scoped>
#conditionTable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
</style>