<!--公共模块--输入设备表格组件-->
<template>
  <div id="inCtrlTable">
    <a-table :columns="columns" :dataSource="data" :pagination="false" bordered :scroll="{ x: 1300 }">
      <span slot="condition" slot-scope="record">
        <a-radio-group v-model="record.condition" :disabled="disabled">
          <a-radio :value="0">
            {{$t('controlllinkage.b1')}}
          </a-radio>
          <a-radio :value="1">
            {{$t('controlllinkage.b2')}}
          </a-radio>            
        </a-radio-group>
      </span>
      <span slot="operate" slot-scope="record">      
        <a>{{ record.operate }}</a>
        <a-divider type="vertical" />
        <a>{{ record.isOperate }}</a>
      </span>
      <span slot="status" slot-scope="record">      
        <a>{{ record.status }}</a>
        <a-divider type="vertical" />
        <a>{{ record.isStatus }}</a>
      </span>
      <span slot="alert" slot-scope="record">
        <a>{{ record.alert }}</a>
        <a-divider type="vertical" />
        <a>{{ record.isAlerte }}</a>
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "inCtrlTable",
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      disabled: true,
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 70,
          fixed: "left",
          align: 'center'
        },
        {
          title: "Gr1",
          dataIndex: "gr1",
          width: 100,
          align: 'center'
        },
        {
          title: "Gr2",
          dataIndex: "gr2",
          width: 80,
          align: 'center'
        },
        {
          title: "Gr3",
          dataIndex: "gr3",
          width: 80,
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b4'),
          dataIndex: "dvName",
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b5'),
          dataIndex: "signalName",
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b6'),
          width: 160,
          scopedSlots: { customRender: 'condition' },
          align: 'center'
        },
        {
          title: "TagPoint",
          dataIndex: "tagPoint",
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b7'),
          children: [          
            {
              title: this.$t('controlllinkage.b8'),
              width: 80,
              align:'center',
              ellipsis: true,
              scopedSlots: { customRender: 'operate' },
            },
            {
              title: this.$t('controlllinkage.b1'),
              width: 80,
              align:'center',
              ellipsis: true,
              scopedSlots: { customRender: 'status' },
            },
            {
              title: this.$t('controlllinkage.b2'),
              width: 80,
              align:'center',
              ellipsis: true,
              scopedSlots: { customRender: 'alert' },
            },
          ],
        }
      ],
    };
  }
};
</script>
<style scoped>
#inCtrlTable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
</style>