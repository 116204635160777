<!--公共模块--联动条件表格组件-->
<template>
  <div id="conditionLinkageTable">
    <a-table :columns="columns" :dataSource="data" :pagination="false" bordered :scroll="{ x: 1300 }">
      <span slot="valid" slot-scope="record">
        <a-radio-group v-model="record.isLinkage">
          <a-radio :value="1">
            {{$t('controlllinkage.b9')}}
          </a-radio>
          <a-radio :value="0">
            {{$t('controlllinkage.b10')}}
          </a-radio>            
        </a-radio-group>
      </span>
      <span slot="condition" slot-scope="record">
        <span>{{record.condition1}}</span>
        <a-select default-value="OFF" v-model="record.condition2" style="width: 120px;margin:0px 10px">
          <a-select-option value="ON">
            ON
          </a-select-option>
          <a-select-option value="OFF">
            OFF
          </a-select-option>        
        </a-select>
        <span>{{record.condition3}}</span>
        <a-select v-model="record.condition4" style="width: 120px;margin:0px 10px">
          <a-select-option value="AND">
            AND
          </a-select-option>
          <a-select-option value="OR">
            OR
          </a-select-option>        
        </a-select>
        <span>{{record.condition5}}</span>
      </span>
      <span slot="isCtrl" slot-scope="record">
        <a-select v-model="record.powerCut" style="width: 120px">
          <a-select-option value="控制">
            {{$t('controlllinkage.b11')}}
          </a-select-option>
          <a-select-option value="不控制">
            {{$t('controlllinkage.b12')}}
          </a-select-option>        
        </a-select>
      </span>
      <span slot="outputDelay" slot-scope="record">
        <a-input v-model="record.outputDelay"></a-input>
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "conditionLinkageTable",
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t('controlllinkage.b13'),
          width: 170,
          fixed: "left",
          scopedSlots: { customRender: 'valid' },
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b14'),
          dataIndex: "outputOperate",
          width: 200,
          align: 'center'
        },
        {
          title: this.$t('controlllinkage.b6'),
          // width: 160,
          align: 'center',
          scopedSlots: { customRender: 'condition' },
        },        
        {
          title: this.$t('controlllinkage.b15'),
          width: 200,
          align: 'center',
          scopedSlots: { customRender: 'isCtrl' },
        },
        {
          title: this.$t('controlllinkage.b16'),
          // dataIndex: "outputDelay",
          width: 160,
          align: 'center',
          scopedSlots: { customRender: 'outputDelay' },
        },        
      ],
    };
  }
};
</script>
<style scoped>
#conditionLinkageTable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
</style>